import { FC, ReactNode, useEffect, useState } from 'react';
import packageJson from '../../package.json';
import { api_get_raceingraces, api_get_tracks, api_getparams_racestats } from '../api.service';
import { Footer } from '../components/footer';
import { Header } from '../components/header';
import './racingraces.scss';

import { RacinRaceView } from '../components/racingraces/racingrace';
import { ApiRacingRace, ApiTrack } from '../types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Select, { DefaultOptionType } from 'antd/es/select';
import { Button, DatePicker } from 'antd';
import dayjs from 'dayjs';

var g_updating_races: boolean = false;
/* -------------------------------------------------------------------------------- *\
|                               MeetingStats
\* -------------------------------------------------------------------------------- */
class MeetingStats
{
    public sic: string = "";
    public races: ApiRacingRace[] = [];

    /* -------------------------------------------------------------------------------- *\
    |                               constructor
    \* -------------------------------------------------------------------------------- */
    constructor(sic: string)
    {
        this.sic = sic;
    }
}
/* -------------------------------------------------------------------------------- *\
|                               DayStats
\* -------------------------------------------------------------------------------- */
class DayStats
{
    public date: string = "";
    public fulldate: string = "";
    public meetings: MeetingStats[] = [];

    /* -------------------------------------------------------------------------------- *\
    |                               constructor
    \* -------------------------------------------------------------------------------- */
    constructor(date: string)
    {
        this.date = date;
        this.fulldate = date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8);
    }
}
/* -------------------------------------------------------------------------------- *\
|                               RacingRacesPage
\* -------------------------------------------------------------------------------- */
export const RacingRacesPage: FC<{ children?: ReactNode }> = ({ children }) =>
{
    const [races, setRaces] = useState<ApiRacingRace[]>([]);
    const [days, setDays] = useState<DayStats[]>([]);

    const [startdate, setStartDate] = useState<string | undefined>();
    const [enddate, setEndDate] = useState<string | undefined>();
    const [track, setTrack] = useState<number>(0);
    const [tracks, setTracks] = useState<DefaultOptionType[]>([{value: 0, label: 'All'}]);

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    /* -------------------------------------------------------------------------------- *\
    |                               changeraces
    \* -------------------------------------------------------------------------------- */
    function changeraces(datestart: string | undefined, dateend: string | undefined, track: number = 0)
    {
        var params: any = {};
        params.datestart = datestart as string;
        params.dateend = dateend as string;
        if (track) params.track = track;

        setSearchParams(params);
        navigate('/races' + api_getparams_racestats(true, datestart, dateend, track, undefined, undefined), { replace: true });
        updateraces(datestart, dateend, track);
    }
    /* -------------------------------------------------------------------------------- *\
    |                               updateraces
    \* -------------------------------------------------------------------------------- */
    function updateraces(datestart: string | undefined, dateend: string | undefined, track: number = 0)
    {
        if (g_updating_races) return;
        g_updating_races = true;
        api_get_raceingraces(datestart, dateend, track).then((races: ApiRacingRace[]) =>
        {
            setRaces(races);
        })
        .catch(() => setRaces([]))
        .finally(() => g_updating_races = false);
    }
    useEffect(() =>
    {
        const now: Date = new Date();
        var dateend: string | undefined = now.getFullYear().toString() + '-' + (now.getMonth() + 1).toString().padStart(2, '0') + '-' + now.getDate().toString().padStart(2, '0');
        const beforenow: Date = new Date(new Date().getTime() - 5 * 24 * 60 * 60 * 1000);
        var datestart: string | undefined = beforenow.getFullYear().toString() + (beforenow.getMonth() + 1).toString().padStart(2, '0') + beforenow.getDate().toString().padStart(2, '0');

        var deftrack: number = 0;

        if (searchParams.get('datestart')) datestart = searchParams.get('datestart')!;
        if (searchParams.get('dateend')) dateend = searchParams.get('dateend')!;

        if(searchParams.get('track')) deftrack = parseInt(searchParams.get('track')!);

        if (datestart === 'null') datestart = undefined;
        if (dateend === 'null') dateend = undefined;

        setStartDate(datestart);
        setEndDate(dateend);
        setTrack(deftrack);

        updateraces(datestart, dateend, deftrack);
        const interval = setTimeout(() => updateraces(datestart, dateend, deftrack), 2000);
        return () => clearTimeout(interval);
    }, [searchParams]);

    useEffect(() =>
    {
        api_get_tracks().then((tracks: ApiTrack[]) => 
        {
            var o: DefaultOptionType[] = [];
            o.push({value: 0, label: 'All'});
            o = o.concat(tracks.map(n => { var e: DefaultOptionType = { value: n.id, label: n.name }; return e; }));
            setTracks(o);
        });

        // eslint-disable-next-line
    }, []);

    useEffect(() =>
    {
        var days: DayStats[] = [];
        for(const apirace of races)
        {
            const date: string = apirace.context.substring(0, 8);
            const sic: string = apirace.context.substring(8, 11);
            
            var df = days.find(n => n.date === date);
            var day: DayStats = df ? df : new DayStats(date);

            var mf = day.meetings.find(n => n.sic === sic);
            var meeting: MeetingStats = mf ? mf : new MeetingStats(sic);

            meeting.races.push(apirace);
            if (!mf) day.meetings.push(meeting);
            if (!df) days.push(day);
        }
        days.sort(function (a, b) { return -a.date.localeCompare(b.date); } );
        for(const day of days)
        {
            day.meetings.sort(function (a, b) { return a.sic.localeCompare(b.sic); } );

            for(const meeting of day.meetings)
            {
                meeting.races.sort(function (a, b) { return a.context.localeCompare(b.context); } );
            }
        }
        setDays(days);
    }, [races]);

    return (
        <div>
            <Header title="Supervision" />
            <div className="racingraces">
                <div className='racingraces__maincont'>
                    <div className='stats__search'>
                        <div className='stats__search__couple'>
                            <div className='stats__search__label'>Start date : </div>
                            <DatePicker className='stats__search__datepicker'
                                value={startdate ? dayjs(startdate) : undefined}
                                onChange={date => { setStartDate(date ? date.format('YYYY-MM-DD') : date)}}
                                format={'YYYY-MM-DD'}
                            />
                        </div>
                        <div className='stats__search__couple'>
                            <div className='stats__search__label'>End date : </div>
                            <DatePicker className='stats__search__datepicker'
                                value={enddate ? dayjs(enddate) : undefined}
                                onChange={date => setEndDate(date ? date.format('YYYY-MM-DD') : date)}
                                format={'YYYY-MM-DD'}
                            />
                        </div>
                        <div className='stats__search__couple'>
                            <div className='stats__search__label'>Track : </div>
                            <Select className='stats__search__select' 
                                value={track}
                                options={tracks}
                                onChange={track => setTrack(track)}
                            />
                        </div>
                        <div className='stats__search__couple'>
                            <div className='stats__search__label'></div>
                            <Button onClick={() => changeraces(startdate, enddate, track)}>Update</Button>
                        </div>
                    </div>
                    {
                        days.map((day, index) =>
                        {
                            return (
                                <div className='racingraces__day' key={'day' + index}>
                                    <div className='racingraces__day__title'>{day.fulldate}</div>
                                    <div className='racingraces__day__details'>
                                    {
                                        day.meetings.map((meeting, index) =>
                                        {
                                            return (
                                                <div className='racingraces__day__details__meeting' key={'details' + index}>
                                                    <RacinRaceView sic={meeting.sic} races={meeting.races} />
                                                </div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            
            <Footer text={'supervision @mclloyd 2024 - version ' + packageJson.version} />
        </div>
        </div>
    );
}
