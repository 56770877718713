import { FC, ReactNode, useEffect, useState } from 'react';
import packageJson from '../../package.json';
import { api_get_trackers, api_getparams_racestats } from '../api.service';
import { Footer } from '../components/footer';
import { Header } from '../components/header';
import './trackers.scss';

import { Button, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ApiTracker } from '../types';

var g_updating_trackers: boolean = false;
/* -------------------------------------------------------------------------------- *\
|                               FirmwareStats
\* -------------------------------------------------------------------------------- */
class FirmwareStats
{
    public name: number = 0;
    public count: number = 0;

    /* -------------------------------------------------------------------------------- *\
    |                               constructor
    \* -------------------------------------------------------------------------------- */
    constructor(name: number)
    {
        this.name = name;
    }
}
/* -------------------------------------------------------------------------------- *\
|                               ServerStats
\* -------------------------------------------------------------------------------- */
class ServerStats
{
    public name: string = "";
    public count: number = 0;
    public firmwares: FirmwareStats[] = [];

    /* -------------------------------------------------------------------------------- *\
    |                               constructor
    \* -------------------------------------------------------------------------------- */
    constructor(name: string)
    {
        this.name = name;
    }
}
/* -------------------------------------------------------------------------------- *\
|                               RacingRacePage
\* -------------------------------------------------------------------------------- */
export const TrackersPage: FC<{ children?: ReactNode }> = () =>
{
    const [trackers, setTrackers] = useState<ApiTracker[]>([]);
    const [firmwares, setFirmwares] = useState<FirmwareStats[]>([]);
    const [servers, setServers] = useState<ServerStats[]>([]);

    const [startdate, setStartDate] = useState<string | undefined>();

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    /* -------------------------------------------------------------------------------- *\
    |                               changetrackers
    \* -------------------------------------------------------------------------------- */
    function changetrackers(datestart: string | undefined)
    {
        var params: any = {};
        params.datestart = datestart as string;

        setSearchParams(params);
        navigate('/trackers' + api_getparams_racestats(false, datestart, undefined, undefined, undefined, undefined), { replace: true });
        updatetrackers(datestart);
    }
    /* -------------------------------------------------------------------------------- *\
    |                               updatetrackers
    \* -------------------------------------------------------------------------------- */
    function updatetrackers(datestart: string | undefined)
    {
        if (g_updating_trackers) return;
        g_updating_trackers = true;
        api_get_trackers(datestart).then((trackers: ApiTracker[]) =>
        {
            setTrackers(trackers);
        })
        .catch(() => setTrackers([]))
        .finally(() => g_updating_trackers = false);
    }
    useEffect(() =>
    {
        const beforenow: Date = new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000);
        var datestart: string | undefined = beforenow.getFullYear().toString() + (beforenow.getMonth() + 1).toString().padStart(2, '0') + beforenow.getDate().toString().padStart(2, '0');

        if (searchParams.get('datestart')) datestart = searchParams.get('datestart')!;

        if (datestart === 'null') datestart = undefined;

        setStartDate(datestart);

        updatetrackers(datestart);
        const interval = setTimeout(() => updatetrackers(datestart), 2000);
        return () => clearTimeout(interval);
    }, [searchParams]);

    useEffect(() =>
    {
        var servers: ServerStats[] = [];
        var firmwares: FirmwareStats[] = [];
        for(const tracker of trackers)
        {
            let ip = tracker.ip;
            if (!ip) ip = 'without server';
            var sf = servers.find(s => s.name === ip);
            if (!sf)
            {
                sf = new ServerStats(ip);
                servers.push(sf);
            }
            sf.count += 1;

            var ff = sf.firmwares.find(f => f.name === tracker.firmware);
            if (!ff)
            {
                ff = new FirmwareStats(tracker.firmware);
                sf.firmwares.push(ff);
            }
            ff.count += 1;

            ff = firmwares.find(f => f.name === tracker.firmware);
            if (!ff)
            {
                ff = new FirmwareStats(tracker.firmware);
                firmwares.push(ff);
            }
            ff.count += 1;
        }
        servers.sort((s1, s2) => s2.name < s1.name ? 1 : -1);
        firmwares.sort((s1, s2) => s2.name - s1.name);
        setServers(servers);
        setFirmwares(firmwares);
    }, [trackers]);

    return (
        <div>
            <Header title="Supervision" />
            <div className="trackers">
                <div className='trackers__maincont'>
                    <div className='stats__search'>
                        <div className='stats__search__couple'>
                            <div className='stats__search__label'>Start date : </div>
                            <DatePicker className='stats__search__datepicker'
                                value={startdate ? dayjs(startdate) : undefined}
                                onChange={date => { setStartDate(date ? date.format('YYYY-MM-DD') : date)}}
                                format={'YYYY-MM-DD'}
                            />
                        </div>
                        <div className='stats__search__couple'>
                            <div className='stats__search__label'></div>
                            <Button onClick={() => changetrackers(startdate)}>Update</Button>
                        </div>
                    </div>
                    <div className='trackers__servers'>
                    {
                        servers.map((server, index) =>
                        {
                            return (
                                <div className='trackers__servers__server' key={'server' + index}>
                                    <div className='trackers__servers__server__title'>{server.name} (count {server.count})</div>
                                    <div className='trackers__servers__server__details'>
                                    {
                                        server.firmwares.map((firmware, index) =>
                                        {
                                            return (
                                                <div className='trackers__servers__server__details__firmware' key={'server' + index + 'firmware' + index}>
                                                    <div className='trackers__servers__server__details__firmware__name'>firmware {firmware.name} : </div>
                                                    <div className='trackers__servers__server__details__firmware__count'>{firmware.count}</div>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                    <div className='trackers__firmwares'>
                        <div className='trackers__firmwares__title'>Firmwares</div>
                        {
                            firmwares.map((firmware, index) =>
                            {
                                return (
                                    <div className='trackers__firmwares__firmware' key={'firmware' + index}>firmware {firmware.name} : {firmware.count}</div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            
            <Footer text={'supervision @mclloyd 2024 - version ' + packageJson.version} />
        </div>
    );
}
