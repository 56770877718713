import { FC, ReactNode } from 'react';
import { ApiRaceStats } from '../../types';
import './detailstats.scss';

/* -------------------------------------------------------------------------------- *\
|                               DetailStatsView
\* -------------------------------------------------------------------------------- */
export const DetailStatsView : FC<{children?: ReactNode, sic: string, races: ApiRaceStats[]}> = ({sic = '', races = []}) =>
{
    const header: string[] = ['runner', 'state','% 10hz', '% PosC', 'latency', 'difftime', 'precision', 'valid'];
    return (
        <div className='detailstats'>
        {
            races.map((race, ir) =>
            {
                return (
                    <div className='detailstats__race' key={'details_race' + ir}>
                        <div className='detailstats__comp'>
                            <div className='detailstats__comp__title'>{race.context.substring(8, 11) + ' race ' + race.context.substring(11)}</div>
                            <table>
                                <tbody>
                                    <tr className='meetingstats__table__row meetingstats__table__row__header'>
                                    {
                                        header.map((h, ih) =>
                                        {
                                            return (
                                                <td className='meetingstats__table__item' key={'header' + ih}>{h}</td>
                                            )
                                        })
                                    }
                                    </tr>
                                    {
                                        race.competitorsfull.map((compfull, icf) =>
                                        {
                                            const percent10hz: string = String(Math.floor(10 * compfull.percent10hz) / 10);
                                            const state10hz = compfull.percent10hz === 100 ? "neutral" : (compfull.percent10hz < 95 ? "alarm" : "alert");
                                            const percentPosC: string = String(Math.floor(10 * compfull.percentPosC) / 10);
                                            const statePosC = compfull.percentPosC === 100 ? "neutral" : (compfull.percentPosC < 95 ? "alarm" : "alert");
                                            const latency: string = String(Math.floor(100 * compfull.latency / 1000) / 100);
                                            const difftime: number = Math.floor(100 * compfull.difftime / 1000) / 100;
                                            const precision: number = Math.floor(compfull.precision * 100);
                                            const stateValid = compfull.valid ? "neutral" : "alarm";

                                            var precisionstr: string = '';
                                            if (precision < 100)
                                            {
                                                precisionstr = String(precision) + 'cm';
                                            }
                                            else
                                            {
                                                precisionstr = String(Math.floor(10 * precision / 100) / 10) + 'm';
                                            }
                                            if (compfull.percent10hz === 0)
                                            {
                                                return (
                                                    <tr key={'details_race' + ir + 'compfull' + icf}>
                                                    <td className='detailstats__comp__item'>{compfull.number}</td>
                                                    <td className='detailstats__comp__item'>{compfull.state}</td>
                                                    <td className='detailstats__comp__item'></td>
                                                    <td className='detailstats__comp__item'></td>
                                                    <td className='detailstats__comp__item'></td>
                                                    <td className='detailstats__comp__item'></td>
                                                    <td className='detailstats__comp__item'></td>
                                                    <td className='detailstats__comp__item' style={{'background': `var(--state-${stateValid})`}}>{String(compfull.valid)}</td>
                                                </tr>
                                                )
                                            }
                                            return (
                                                <tr key={'details_race' + ir + 'compfull' + icf}>
                                                    <td className='detailstats__comp__item'>{compfull.number}</td>
                                                    <td className='detailstats__comp__item'>{compfull.state}</td>
                                                    <td className='detailstats__comp__item' style={{'background': `var(--state-${state10hz})`}}>{ percent10hz + '%'}</td>
                                                    <td className='detailstats__comp__item' style={{'background': `var(--state-${statePosC})`}}>{percentPosC + '%'}</td>
                                                    <td className='detailstats__comp__item'>{latency}</td>
                                                    <td className='detailstats__comp__item'>{difftime ? difftime : ' '}</td>
                                                    <td className='detailstats__comp__item'>{difftime ? precisionstr : ' '}</td>
                                                    <td className='detailstats__comp__item' style={{'background': `var(--state-${stateValid})`}}>{String(compfull.valid)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                )
            })
        }
        </div>
);
}
