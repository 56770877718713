import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { VisioCompetitor } from '../../types';
import './visiocompetitor.scss';

/* -------------------------------------------------------------------------------- *\
|                               drawGraph
\* -------------------------------------------------------------------------------- */
function drawGraph(ctx: CanvasRenderingContext2D, width: number, height: number, comp: VisioCompetitor, state: string)
{
    ctx.fillStyle = "black";
    ctx.beginPath();
    ctx.rect(0, 0, width, height);
    ctx.fill();

    if (comp.state !== "NP" && comp.colors.length)
    {
        var color = '';
        var x1 = 0;
        var x2 = 0;
        comp.colors.forEach(d =>
        {
            if (color === '')
            {
                color = d;
                ctx.fillStyle = color;
            }

            if (color !== d)
            {
                ctx.beginPath();
                ctx.rect(x1, 0, x2, height);
                ctx.fill();
                x1 = x2;
                color = d;
                ctx.fillStyle = color;
            }
            
            x2 = x2 + (width / comp.colors.length);
        });
        if (color !== '')
        {
            if (state && state !== "DP") color = "black";
            ctx.fillStyle = color;
            ctx.beginPath();
            ctx.rect(x1, 0, x2, height);
            ctx.fill();
            x1 = x2;
            color = '';
        }
    }
}
/* -------------------------------------------------------------------------------- *\
|                               VisiocompetitorView
\* -------------------------------------------------------------------------------- */
export const VisiocompetitorView : FC<{children?: ReactNode, comp: VisioCompetitor, state: string}> = ({comp, state}) =>
{
    const refcanvas = useRef<HTMLCanvasElement>(null);

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    
    useEffect(() =>
    {
        if (refcanvas && refcanvas.current && width !== refcanvas.current.clientWidth) setWidth(refcanvas.current.clientWidth);
        if (refcanvas && refcanvas.current && height !== refcanvas.current.clientHeight) setHeight(refcanvas.current.clientHeight);
    }, [width, height, refcanvas]);

    useEffect(() =>
    {
        const canvas = refcanvas.current;
        if (canvas)
        {
            const context = canvas.getContext('2d');
            if (context)
            {
                drawGraph(context, width, height, comp, state);
            }
        }
    }, [width, height, comp, state]);
    
    return (
        <div className='visiocompetitor'>
            <canvas ref={refcanvas} width={width} height={height}/>
        </div>
    );
}
