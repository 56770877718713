import { FC, ReactNode } from 'react';
import { ApiRaceStats } from '../../types';
import { VisiocompetitorView } from './visiocompetitor';
import './visiograph.scss';
import { VisiolegendsView } from './visiolegends';

/* -------------------------------------------------------------------------------- *\
|                               VisiographView
\* -------------------------------------------------------------------------------- */
export const VisiographView : FC<{children?: ReactNode, sic: string, race: ApiRaceStats}> = ({race}) =>
{
    const distance: string = race.visiodatas && race.visiodatas.distance ? ' (' + race.visiodatas.distance + 'm)' : '';

    return (
        <div className='visiograph'>
        {
            <div className='visiograph__comp'>
                <div className='visiograph__comp__title'>{race.context.substring(8, 11) + ' race ' + race.context.substring(11) + distance}</div>
                <div className='visiograph__comp__table'>
                    {
                        race.visiodatas &&
                        race.visiodatas.competitors &&
                        race.visiodatas.competitors.map((compfull, icf) =>
                        {
                            var s: string = "";
                            const cf = race.competitorsfull.find(c => c.number === compfull.number);
                            if (cf && cf.state) s = cf.state;
                            const state: string = s && s !== 'DP' ? (' (' + s + ')') : '';
                            return (
                                <div className='visiograph__comp__row' key={'details_race_compfull' + icf}>
                                    <div className='visiograph__comp__number'>{compfull.number + state}</div>
                                    <div className='visiograph__comp__graph'>
                                        <VisiocompetitorView comp={compfull} state={s}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='visiograph__comp__row'>
                    <div className='visiograph__comp__number'></div>
                    <div className='visiograph__comp__graph'>
                        <VisiolegendsView distance={race.visiodatas && race.visiodatas.distance ? race.visiodatas.distance : 0}/>
                    </div>
                </div>
            </div>
        }
        </div>
    );
}
