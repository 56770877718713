import { Button, DatePicker, Select } from 'antd';
import { DefaultOptionType } from 'antd/es/cascader';
import dayjs from 'dayjs';
import { FC, Fragment, ReactNode, useCallback, useEffect, useState } from 'react';
import packageJson from '../../package.json';
import { api_get_operators, api_get_racestats, api_get_racingservers, api_get_tracks, api_getparams_racestats } from '../api.service';
import { Footer } from '../components/footer';
import { Header } from '../components/header';
import { DetailStatsView } from '../components/racestats/detailstats';
import { InfosStatsView } from '../components/racestats/infostats';
import { MeetingStatsView } from '../components/racestats/meetingstats';
import { ApiAnswerRaceStats, ApiOperator, ApiRaceStats, ApiRacingServer, ApiStatsRaceStats, ApiTrack } from '../types';
import './stats.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';

var g_updating_racestats: boolean = false;

/* -------------------------------------------------------------------------------- *\
|                               MeetingStats
\* -------------------------------------------------------------------------------- */
class MeetingStats
{
    public sic: string = "";
    public races: ApiRaceStats[] = [];

    /* -------------------------------------------------------------------------------- *\
    |                               constructor
    \* -------------------------------------------------------------------------------- */
    constructor(sic: string)
    {
        this.sic = sic;
    }
}
/* -------------------------------------------------------------------------------- *\
|                               DayStats
\* -------------------------------------------------------------------------------- */
class DayStats
{
    public date: string = "";
    public fulldate: string = "";
    public meetings: MeetingStats[] = [];
    public showdetail: boolean = false;

    /* -------------------------------------------------------------------------------- *\
    |                               constructor
    \* -------------------------------------------------------------------------------- */
    constructor(date: string)
    {
        this.date = date;
        this.fulldate = date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8);
    }
}
/* -------------------------------------------------------------------------------- *\
|                               StatsPage
\* -------------------------------------------------------------------------------- */
export const StatsPage: FC<{ children?: ReactNode }> = ({ children }) =>
{
    const [updatestate, updateState] = useState<number>(0);
    const forceUpdate = useCallback(() => { var s = updatestate + 1; updateState(s)}, [updatestate]);
    
    const [racestats, setRacestats] = useState<ApiRaceStats[]>([]);
    const [days, setDays] = useState<DayStats[]>([]);
    const [stats, setStats] = useState<ApiStatsRaceStats>(new ApiStatsRaceStats());

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    
    /* -------------------------------------------------------------------------------- *\
    |                               changeracestats
    \* -------------------------------------------------------------------------------- */
    function changeracestats(datestart: string | undefined, dateend: string | undefined, track: number = 0, server: number = 0, operator: number = 0)
    {
        var params: any = {};
        params.datestart = datestart as string;
        params.dateend = dateend as string;
        if (track) params.track = track;
        if (server) params.server = server;
        if (operator) params.operator = operator;

        setSearchParams(params);
        navigate('/stats' + api_getparams_racestats(true, datestart, dateend, track, server, operator), { replace: true });
        updateracestats(datestart, dateend, track, server, operator);
    }
    /* -------------------------------------------------------------------------------- *\
    |                               updateracestats
    \* -------------------------------------------------------------------------------- */
    function updateracestats(datestart: string | undefined, dateend: string | undefined, track: number = 0, server: number = 0, operator: number = 0)
    {
        if (g_updating_racestats) return;
        g_updating_racestats = true;
        api_get_racestats(datestart, dateend, track, server, operator).then((datas: ApiAnswerRaceStats) =>
        {
            setRacestats(datas.races);
            setStats(datas.stats);
        })
        .catch(() => setRacestats([]))
        .finally(() => g_updating_racestats = false);
    }
    useEffect(() =>
    {
        const now: Date = new Date();
        var dateend: string | undefined = now.getFullYear().toString() + '-' + (now.getMonth() + 1).toString().padStart(2, '0') + '-' + now.getDate().toString().padStart(2, '0');
        const beforenow: Date = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
        var datestart: string | undefined = beforenow.getFullYear().toString() + (beforenow.getMonth() + 1).toString().padStart(2, '0') + beforenow.getDate().toString().padStart(2, '0');

        var deftrack: number = 0;
        var defserver: number = 0;
        var defoperator: number = 0;

        if (searchParams.get('datestart')) datestart = searchParams.get('datestart')!;
        if (searchParams.get('dateend')) dateend = searchParams.get('dateend')!;

        if(searchParams.get('track')) deftrack = parseInt(searchParams.get('track')!);
        if(searchParams.get('server')) defserver = parseInt(searchParams.get('server')!);
        if(searchParams.get('operator')) defoperator = parseInt(searchParams.get('operator')!);

        if (datestart === 'null') datestart = undefined;
        if (dateend === 'null') dateend = undefined;

        setStartDate(datestart);
        setEndDate(dateend);
        setTrack(deftrack);
        setServer(defserver);
        setOperator(defoperator);

        updateracestats(datestart, dateend, deftrack, defserver, defoperator);
        const interval = setTimeout(() => updateracestats(datestart, dateend, deftrack, defserver, defoperator), 2000);
        return () => clearTimeout(interval);
    }, [searchParams]);

    useEffect(() =>
    {
        api_get_operators().then((operators: ApiOperator[]) => 
        {
            var o: DefaultOptionType[] = [];
            o.push({value: 0, label: 'All'});
            o = o.concat(operators.map(n => { var e: DefaultOptionType = { value: n.id, label: n.name }; return e; }));
            setOperators(o);

            o = [];
            o.push({value: 0, label: 'Not set'});
            o = o.concat(operators.map(n => { var e: DefaultOptionType = { value: n.id, label: n.name }; return e; }));
            setOperators2(o);
        });

        api_get_racingservers().then((servers: ApiRacingServer[]) => 
        {
            var o: DefaultOptionType[] = [];
            o.push({value: 0, label: 'All'});
            o = o.concat(servers.map(n => { var e: DefaultOptionType = { value: n.id, label: n.name }; return e; }));
            setServers(o);
        });
        api_get_tracks().then((tracks: ApiTrack[]) => 
        {
            var o: DefaultOptionType[] = [];
            o.push({value: 0, label: 'All'});
            o = o.concat(tracks.map(n => { var e: DefaultOptionType = { value: n.id, label: n.name }; return e; }));
            setTracks(o);
        });

    // eslint-disable-next-line
    }, []);

    useEffect(() =>
    {
        var days: DayStats[] = [];
        for(const apirace of racestats)
        {
            const date: string = apirace.context.substring(0, 8);
            const sic: string = apirace.context.substring(8, 11);
            
            var df = days.find(n => n.date === date);
            var day: DayStats = df ? df : new DayStats(date);

            var mf = day.meetings.find(n => n.sic === sic);
            var meeting: MeetingStats = mf ? mf : new MeetingStats(sic);

            meeting.races.push(apirace);
            if (!mf) day.meetings.push(meeting);
            if (!df) days.push(day);
        }
        days.sort(function (a, b) { return -a.date.localeCompare(b.date); } );
        for(const day of days)
        {
            day.meetings.sort(function (a, b) { return a.sic.localeCompare(b.sic); } );

            for(const meeting of day.meetings)
            {
                meeting.races.sort(function (a, b) { return a.context.localeCompare(b.context); } );
            }
        }
        setDays(days);
    }, [racestats]);

    const [startdate, setStartDate] = useState<string | undefined>();
    const [enddate, setEndDate] = useState<string | undefined>();
    const [track, setTrack] = useState<number>(0);
    const [tracks, setTracks] = useState<DefaultOptionType[]>([{value: 0, label: 'All'}]);
    const [server, setServer] = useState<number>(0);
    const [servers, setServers] = useState<DefaultOptionType[]>([{value: 0, label: 'All'}]);
    const [operator, setOperator] = useState<number>(0);
    const [operators, setOperators] = useState<DefaultOptionType[]>([{value: 0, label: 'All'}]);
    const [operators2, setOperators2] = useState<DefaultOptionType[]>([{value: 0, label: 'All'}]);

    return (
        <div>
            <Header title="Supervision" />
            <div className="stats">
                <div className='stats__maincont'>
                    <div className='stats__search'>
                        <div className='stats__search__couple'>
                            <div className='stats__search__label'>Start date : </div>
                            <DatePicker className='stats__search__datepicker'
                                value={startdate ? dayjs(startdate) : undefined}
                                onChange={date => { setStartDate(date ? date.format('YYYY-MM-DD') : date)}}
                                format={'YYYY-MM-DD'}
                            />
                        </div>
                        <div className='stats__search__couple'>
                            <div className='stats__search__label'>End date : </div>
                            <DatePicker className='stats__search__datepicker'
                                value={enddate ? dayjs(enddate) : undefined}
                                onChange={date => setEndDate(date ? date.format('YYYY-MM-DD') : date)}
                                format={'YYYY-MM-DD'}
                            />
                        </div>
                        <div className='stats__search__couple'>
                            <div className='stats__search__label'>Track : </div>
                            <Select className='stats__search__select' 
                                value={track}
                                options={tracks}
                                onChange={track => setTrack(track)}
                            />
                        </div>
                        <div className='stats__search__couple'>
                            <div className='stats__search__label'>Server : </div>
                            <Select className='stats__search__select' 
                                value={server}
                                options={servers}
                                onChange={server => setServer(server)}
                            />
                        </div>
                        <div className='stats__search__couple'>
                            <div className='stats__search__label'>Operator : </div>
                            <Select className='stats__search__select' 
                                value={operator}
                                options={operators}
                                onChange={operator => setOperator(operator)}
                            />
                        </div>
                        <div className='stats__search__couple'>
                            <div className='stats__search__label'></div>
                            <Button onClick={() => changeracestats(startdate, enddate, track, server, operator)}>Update</Button>
                        </div>
                    </div>
                    <div className='stats__stats'>
                        <div className='stats__stats__couple'>
                            <div className='stats__stats__label'>Race count: </div>
                            <div className='stats__stats__value'>{stats.racecount}</div>
                        </div>
                        <div className='stats__stats__couple'>
                            <div className='stats__stats__label'>Valid: </div>
                            <div className='stats__stats__value'>{stats.validcount + (stats.racecount > 0 ? ' (' + (Math.round(stats.validcount / stats.racecount * 100) + '%)') : '')}</div>
                        </div>
                        <div className='stats__stats__couple'>
                            <div className='stats__stats__label'>Mec: </div>
                            <div className='stats__stats__value'>{stats.meccount + (stats.racecount > 0 ? ' (' + (Math.round(stats.meccount / stats.racecount * 100) + '%)') : '')}</div>
                        </div>
                    </div>
                    {
                        days.map((day, index) =>
                        {
                            return (
                                <div className='stats__day' key={'statsday' + index}>
                                    <div className='stats__day__title'>
                                        <div className='stats__day__title__element'>
                                            <div className='stats__day__title__element__button' onClick={() => { day.showdetail = !day.showdetail; forceUpdate(); }}>details</div>
                                        </div>
                                        <div className='stats__day__title__element'>{day.fulldate}</div>
                                        <div className='stats__day__title__element'></div>
                                    </div>
                                    <div className='stats__day__meetings'>
                                    {
                                        day.meetings.map((meeting, index) =>
                                        {
                                            return (
                                                <div className='stats__day__meetings__meeting' key={'statsmeeting' + index}>
                                                    <MeetingStatsView  key={'statsmeeting' + index} showdate={false} date={day.date} races={meeting.races} sic={meeting.sic} operators={operators2} />
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                    {
                                        day.showdetail &&
                                        <div className='stats__day__infos'>
                                        {
                                            day.meetings.map((meeting, index) =>
                                            {
                                                var infosmeeting = meeting.races.find(n => n.infolines && n.infolines.length > 0);
                                                return (
                                                    <Fragment key={'inf' + index}>
                                                    {
                                                        infosmeeting && <InfosStatsView  key={'statsinfos' + index} sic={meeting.sic} races={meeting.races} /> 
                                                    }
                                                    <DetailStatsView key={'statsdetails' + index} sic={meeting.sic} races={meeting.races} />
                                                    </Fragment>
                                                )
                                            })
                                        }
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Footer text={'supervision @mclloyd 2024 - version ' + packageJson.version} />
        </div>
    );
}
