import { FC, ReactNode, useEffect, useState } from 'react';
import './header.scss';
import { ApiMeetings, Meeting, RaceTime } from '../types';
import { api_get_program } from '../api.service';

var g_headerupdating_program: boolean = false;
/* -------------------------------------------------------------------------------- *\
|                               secondtostring
\* -------------------------------------------------------------------------------- */
function secondtostring(seconds: number): string
{
    const hour = Math.floor(seconds / 60 / 60);
    const minutes = Math.floor(seconds / 60) % 60;
    const sec = seconds % 60;
    if (hour > 0)
    {
        return hour + "h" + String(minutes).padStart(2, '0') + "m" + String(sec).padStart(2, '0');
    }
    return minutes + "m" + String(sec).padStart(2, '0');
}
/* -------------------------------------------------------------------------------- *\
|                               Header
\* -------------------------------------------------------------------------------- */
export const Header : FC<{children?: ReactNode, title?: string}> = ({title = ''}) =>
{
    const [showmenu, setShowMenu] = useState(false);
    const [nearestrace, setNearestrace] = useState<RaceTime | null>(null);

    /* -------------------------------------------------------------------------------- *\
    |                               updateprogram
    \* -------------------------------------------------------------------------------- */
    function updateprogram()
    {
        if (g_headerupdating_program) return;
        g_headerupdating_program = true;
        api_get_program().then((input: ApiMeetings) =>
        {
            if (!input) return;

            try
            {
                var race: RaceTime | null = null;
                var now: Date = new Date();
                const timenow: number = Math.floor(now.getHours()) * 60 + Math.floor(now.getMinutes());
                for(const meeting of input.meetings)
                {
                    const rt = Meeting.getnearestrace(meeting, timenow);
                    if (rt)
                    {
                        if (!race)
                        {
                            race = rt;
                        }
                        else if (rt.Time < race.Time)
                        {
                            race = rt;
                        }
                    }
                }
                setNearestrace(race);
            }
            catch
            {
                setNearestrace(null);
            }
        })
        .catch(() => { setNearestrace(null); })
        .finally(() => g_headerupdating_program = false);
    }
    useEffect(() =>
    {
        updateprogram();
        const interval = setInterval(() =>
        {
            updateprogram();
        }, 15000);

        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);

    const [time, setTime] = useState<number>(0);
    useEffect(() =>
    {
        const interval = setInterval(() =>
        {
            const now = new Date();
            setTime(Math.floor(now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds()));
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const timerace: number = nearestrace && nearestrace.Time ? nearestrace.Time * 60 : 0;
    const next: string = timerace && time && timerace > time  ? " " + secondtostring(timerace - time) : "";
    const text: string = nearestrace ? ('Next race ' + next +  " (" + nearestrace.StartTime + " R" + nearestrace.Number + " " + nearestrace.Track + ")") : "No race scheduled";
    return (
        <div className='header'>
            <div className='header__logo'>
                <img className='header__logo__img' src={'/images/logo-mclloyd.svg'} alt="McLloyd"/>
                <div className='header__logo__text'>{title}</div>
            </div>
            <div className='header__nextrace'>{text}</div>
            <div className='header__menu' onClick={() => { setShowMenu(true); }}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className={'header__menu__back ' + (showmenu ? 'header__menu__active' : '')} onClick={() => setShowMenu(false)}>
                    <div className='header__menu__back__content' onClick={e => e.stopPropagation()}>
                        <a className='header__menu__back__content__item' href='/'>Home</a>
                        <a className='header__menu__back__content__item' href='/stats'>Stats</a>
                        <a className='header__menu__back__content__item' href='/races'>Races</a>
                        <a className='header__menu__back__content__item' href='/graphs'>Visiographs</a>
                        <a className='header__menu__back__content__item' href='/trackers'>Trackers</a>
                    </div>
            </div>
        </div>
    );
}
