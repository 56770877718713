import { FC, useEffect, useState } from 'react';
import { Meeting, RacingInfos } from '../../types';
import './program.scss';
import { RaceInfosView } from './raceinfos';
import { RollingTextView } from './rollingtext';

/* -------------------------------------------------------------------------------- *\
|                               ProgramProps
\* -------------------------------------------------------------------------------- */
interface ProgramProps
{
	list: Meeting[],
	lives: RacingInfos[],
    date: string,
	children?: Node
}
/* -------------------------------------------------------------------------------- *\
|                               ProgramView
\* -------------------------------------------------------------------------------- */
export const ProgramView : FC<ProgramProps> = ({list, lives, date}) =>
{
    const [timervalue, setTimerValue] = useState(0);

    useEffect(() =>
    {
        const interval = setInterval(() =>
        {
            setTimerValue((t) => (t + 1) % 100);
        }, 3000);
        
        return () => clearInterval(interval);
    }, []);
    
    var now: Date = new Date();
    const timenow: number = Math.floor(now.getHours()) * 60 + Math.floor(now.getMinutes());
    if (date && date.length === 8)
    {
        now = new Date(Number(date.substring(0, 4)), Number(date.substring(4, 6)) - 1, Number(date.substring(6, 8)));
    }
    const datestr: string = String(now.getFullYear()).padStart(4, '0') + '-' + String(now.getMonth() + 1).padStart(2, '0') + "-" + String(now.getDate()).padStart(2, '0');

    return (
        <div className='program'>
            <div className='program__cont'>
                <div className='program__title'>Daily program {datestr}</div>
                <div className='program__meetings'>
                {
                    list.map((meeting, index) =>
                    {
                        const excludedsic: string[] = process.env.REACT_APP__EXCLUDED_SICS ? process.env.REACT_APP__EXCLUDED_SICS.split(',') : [];
                        const showsicmec: boolean = excludedsic.indexOf(meeting.sic) === -1;
                        return (
                            <div className='program__meeting' key={'meeting_' + index}>
                                <div className='program__meeting__name'>{ meeting.sic + " " + meeting.Track + (meeting.Number ? ' (M' + meeting.Number + ')' : '') }</div>
                                <div className='program__meeting__races'>
                                    {
                                        meeting.races &&
                                        meeting.races.map((race, index) =>
                                        {
                                            const els: string[] = race.StartTime.split(":");
                                            const timerace: number = Number(els[0]) * 60 + Number(els[1]);
                                            const showmec: boolean = showsicmec && timerace + 30 < timenow; 
                                            const mecled1: string = race.mec && race.mec.mecfull ? "good" : "alarm";
                                            const mecled2: string = race.mec && race.mec.mecsectional ? "good" : "alarm";
                                            const liveinfo: RacingInfos | undefined = lives.find((live) => live.sic === meeting.sic && live.norace === race.Number);
                                            return (
                                                <div className='program__meeting__races__race' key={'race_' + index}>
                                                    {
                                                        showmec &&
                                                        (
                                                            <div className='program__meeting__races__race__mecstates'>
                                                                <div className='program__meeting__races__race__led'  style={{'background': `var(--state-${mecled1})`}}></div>
                                                                <div className='program__meeting__races__race__led'  style={{'background': `var(--state-${mecled2})`}}></div>
                                                            </div>
                                                        )
                                                    }
                                                    <div className='program__meeting__races__line1'>
                                                        <div className='program__meeting__races__number'>{"R" + race.Number}</div>
                                                        <div className='program__meeting__races__time'>{race.StartTime}</div>
                                                        <RollingTextView race={race} timer={timervalue}/>
                                                    </div>
                                                    <RaceInfosView infos={liveinfo}/>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
                </div>
            </div>
        </div>
    );
}
