import { FC, ReactNode } from 'react';
import { ApiRaceStats } from '../../types';
import './meetinggraphs.scss';
import { VisiographView } from './visiograph';

/* -------------------------------------------------------------------------------- *\
|                               MeetinggraphsView
\* -------------------------------------------------------------------------------- */
export const MeetinggraphsView : FC<{children?: ReactNode, sic: string, races: ApiRaceStats[]}> = ({sic = '', races = []}) =>
{
    return (
        <div className='meetinggraphs'>
        {
            races.map((race, ir) =>
            {
                return (
                    <div className='meetinggraphs__graph' key={'graph_' + ir}>
                        <VisiographView race={race} sic={sic}>
                        </VisiographView>
                    </div>
                )
            })
        }
        </div>
);
}
