import { FC, ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import './visiocompetitor.scss';

/* -------------------------------------------------------------------------------- *\
|                               Constantes
\* -------------------------------------------------------------------------------- */
const c_fontsize_label: number = 8;
const c_margintext: number = 12;
/* -------------------------------------------------------------------------------- *\
|                               drawGraph
\* -------------------------------------------------------------------------------- */
function drawGraph(ctx: CanvasRenderingContext2D, width: number, height: number, distance: number)
{
    ctx.fillStyle = "black";
    ctx.beginPath();
    ctx.rect(0, 0, width, height);
    ctx.fill();

    if (distance > 0)
    {
        ctx.strokeStyle = "white";
        ctx.fillStyle = "white";
        ctx.font = `${c_fontsize_label}px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`;
        ctx.textAlign = "left";
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(0, 5);
        ctx.stroke();
        ctx.fillText("Start", 0, c_margintext);
        ctx.textAlign = "right";
        ctx.beginPath();
        ctx.moveTo(width - 1, 0);
        ctx.lineTo(width - 1, 5);
        ctx.stroke();
        ctx.fillText("Finish", width, c_margintext);
        ctx.textAlign = "center";

        var lastx = width;
        for (var d = 0; d < distance; d += 100)
        {
            if (d > 0)
            {
                var xt: number = width - d * width / distance;
                ctx.beginPath();
                ctx.moveTo(xt, 0);
                ctx.lineTo(xt, 5);
                ctx.stroke();
                if (lastx - xt > 30 && xt > 30)
                {
                    ctx.fillText(d.toString(), xt, c_margintext);
                    lastx = xt;
                }
            }
        }
    }
}
/* -------------------------------------------------------------------------------- *\
|                               useWindowSize
\* -------------------------------------------------------------------------------- */
function useWindowSize()
{
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() =>
    {
        function updateSize()
        {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}
/* -------------------------------------------------------------------------------- *\
|                               VisiolegendsView
\* -------------------------------------------------------------------------------- */
export const VisiolegendsView : FC<{children?: ReactNode, distance: number}> = ({distance}) =>
{
    const refcanvas = useRef<HTMLCanvasElement>(null);

    const [windowwidth, windowheight] = useWindowSize();
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    
    useEffect(() =>
    {
        if (refcanvas && refcanvas.current && width !== refcanvas.current.clientWidth) setWidth(refcanvas.current.clientWidth);
        if (refcanvas && refcanvas.current && height !== refcanvas.current.clientHeight) setHeight(refcanvas.current.clientHeight);
    }, [windowwidth, windowheight, width, height, refcanvas]);

    useEffect(() =>
    {
        const canvas = refcanvas.current;
        if (canvas)
        {
            const context = canvas.getContext('2d');
            if (context)
            {
                drawGraph(context, width, height, distance);
            }
        }
    }, [width, height, distance]);
    
    return (
        <div className='visiocompetitor'>
            <canvas ref={refcanvas} width={width} height={height}/>
        </div>
    );
}
